import React from 'react';
import './Body.css'; // Import CSS file for styling
import { ExpandableUsecases } from './ExpandableUsecases';

function Body({homeRef, openContactModal}) {
  return (
    <div ref={homeRef} className="body-container">
      <div className='sub-body-2'>
        <ExpandableUsecases/>    
      </div>
    </div>
  );
}

export default Body;
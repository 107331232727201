import './App.css';
import Main from './main';

function App() {
  return (
      <Main/>
  );
}

export default App;
